import React, { FC, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./content.module.scss";
import TechnicalWork from "@app/components/technicalWork/TechnicalWork";
import { ProfileHeader } from "@components/profile/Header/Header";
import { profileContext } from "@context/profileContext";
import {
	ADAPTIVE_BREAKPOINTS,
	CUSTOM_EVENT__PROFILE_CONTENT_SCROLLED_DOWN,
	DEFAULT_DELAY,
	TRANSITION_TIMEOUT,
} from "@common/constants";
import { CSSTransition } from "react-transition-group";
import ProfileTabsControl from "@components/profile/reused/Tabs/ProfileTabsControl";
import SimpleBar from "simplebar-react";
import { Switch, Route } from "react-router-dom";
import { IRoute } from "@app/router";
import throttle from "lodash.throttle";
import { useEmitter } from "@hooks/customEvent";
import { Button } from "@components/ui";
import {
	ButtonIconsVariant,
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import useWindowSize from "@hooks/useWindowSize";
import { useScrollToTop } from "@hooks/useScrollToTop";

interface IProfileContentProps {
	routes: IRoute[];
}

export const ProfileContent: FC<IProfileContentProps> = ({
	routes,
	children,
}) => {
	const { tabs, scrollableContentRef } = useContext(profileContext),
		controlsRef = useRef<HTMLDivElement | null>(null),
		[showToUpButton, setShowToUpButton] = useState<boolean>(false),
		ButtonToUpRef = useRef<HTMLDivElement | null>(null),
		{ width: ww } = useWindowSize(),
		scrollToTop = useScrollToTop("smooth");
	const scrollIsEndEvent = useEmitter(
		CUSTOM_EVENT__PROFILE_CONTENT_SCROLLED_DOWN,
	);
	let buttonToUpShowedFlag = false;

	const onScroll: EventListener = throttle((event: Event) => {
		const el = event.target as HTMLElement,
			{ documentElement } = document,
			isScrollEnd: boolean =
				ww < ADAPTIVE_BREAKPOINTS.xl
					? documentElement.scrollHeight - documentElement.scrollTop ===
					  documentElement.clientHeight
					: el.scrollHeight - el.scrollTop === el.clientHeight,
			scrollPercentage: number =
				(100 * el.scrollTop) / (el.scrollHeight - el.clientHeight);
		if ((isScrollEnd || scrollPercentage > 90) && ww > ADAPTIVE_BREAKPOINTS.xl)
			scrollIsEndEvent({});

		if ((el.scrollTop || documentElement.scrollTop) > 150) {
			if (!buttonToUpShowedFlag) {
				buttonToUpShowedFlag = true;
				setShowToUpButton(true);
			}
		} else {
			if (buttonToUpShowedFlag) {
				buttonToUpShowedFlag = false;
				setShowToUpButton(false);
			}
		}
	}, DEFAULT_DELAY);

	useEffect(() => {
		const { current } = scrollableContentRef;

		// if (ww < ADAPTIVE_BREAKPOINTS.xl) {
		//  	current && (current as HTMLDivElement).removeEventListener('scroll', onScroll);
		//  	document.addEventListener('scroll', onScroll);
		// } else {
		document.removeEventListener("scroll", onScroll);
		current && (current as HTMLDivElement).addEventListener("scroll", onScroll);
		// }

		return () => {
			current &&
				(current as HTMLDivElement).removeEventListener("scroll", onScroll);
			document.removeEventListener("scroll", onScroll);
		};
	}, [ww]);
	return (
		<div className={classNames(styles.root)}>
			<div className={"h-100"}>
				<ProfileHeader />
				<div className={styles.area}>
					{ww >= ADAPTIVE_BREAKPOINTS.xl && (
						<CSSTransition
							in={tabs.length > 0}
							classNames={"animation--slide-y"}
							timeout={TRANSITION_TIMEOUT}
							unmountOnExit
							nodeRef={controlsRef}>
							<ProfileTabsControl />
						</CSSTransition>
					)}

					<SimpleBar
						className={styles.view}
						autoHide={false}
						scrollableNodeProps={{ ref: scrollableContentRef }}>
						<Switch>
							{/* {routes.map((route) => (
								<Route
									key={route.path}
									component={route.Component}
									{...route}
								/>
							))} */}
							<TechnicalWork />
						</Switch>
					</SimpleBar>

					<CSSTransition
						in={showToUpButton}
						classNames={"animation--slide-y"}
						timeout={TRANSITION_TIMEOUT}
						unmountOnExit
						nodeRef={ButtonToUpRef}>
						<div ref={ButtonToUpRef} className={styles.btnToUpWrap}>
							<Button
								wrapperClassName={styles.btnToUp}
								as={ButtonTypesEnum.BUTTON}
								variant={ButtonVariant.icon}
								icon={ButtonIconsVariant.arrowUp}
								onClick={scrollToTop}
							/>
						</div>
					</CSSTransition>
				</div>
			</div>

			{/* {children} */}
		</div>
	);
};
