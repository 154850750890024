import React, { FC, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import {
	ProfileAbout,
	ProfileCabinet,
	ProfileClub,
	ProfileData,
	ProfileHistory,
	ProfileProjects,
	ProfileQuestions,
	ProfileRequisites,
} from "@components/investor/Views";
import { IRoute, InvestorRouteNamesEnum } from "@router/index";
import { ProfileAnalytics } from "@components/investor/Views/Analytics/Analytics";
import ProfileProjectDetail from "@components/investor/ProjectDetail/ProjectDetail";
import { ProjectsProvider } from "@context/projectsContext";
import { PersonalDataProvider } from "@context/personalDataContext";
import ProfileQualification from "@components/investor/Views/Qualification/Qualification";
import { QualificationProvider } from "@context/qualificationContext";
import { investContext } from "@context/investContext";
import InvestRequestModal from "@components/investor/InvestModals/InvestRequest/InvestRequestModal";
import SuccessModal from "@components/common/Modal/SuccessModal";
import InvestCancelModal from "@components/investor/InvestModals/InvestCancel/InvestCancelModal";
import { ProfileContent } from "@components/profile/Content/Content";
import ProfileMobileMenu from "@components/profile/MobileMenu/ProfileMobileMenu";
import { INVESTOR_NAVIGATION } from "@components/investor/Aside/Aside";
import useWindowSize from "@hooks/useWindowSize";
import { ADAPTIVE_BREAKPOINTS } from "@common/constants";
import { QuestionsProvider } from "@context/questionsContext";
import InvestRequestConfirmModal from "@components/investor/InvestModals/InvestRequest/InvestRequestConfirmModal";
import InvestCancelConfirmModal from "@components/investor/InvestModals/InvestCancel/InvestCancelConfirmModal";

export const InvestorContent: FC = () => {
	const { path } = useRouteMatch(),
		{
			requestSuccessModal,
			setRequestSuccessModal,
			setCancelSuccessModal,
			cancelSuccessModal,
		} = useContext(investContext),
		{ width: ww } = useWindowSize();
	const ProfileRoutes: IRoute[] = [
		{
			path: path + InvestorRouteNamesEnum.CABINET,
			Component: ProfileCabinet,
		},
		{
			path: path + InvestorRouteNamesEnum.PROJECT,
			Component: ProfileProjectDetail,
		},
		{
			path: path + InvestorRouteNamesEnum.PROJECTS,
			Component: ProfileProjects,
		},
		{
			path: path + InvestorRouteNamesEnum.CLUB,
			Component: ProfileClub,
		},
		{
			path: path + InvestorRouteNamesEnum.QUESTIONS,
			Component: ProfileQuestions,
		},
		// {
		// 	path: path+InvestorRouteNamesEnum.ABOUT,
		// 	Component: ProfileAbout
		// },
		{
			path: path + InvestorRouteNamesEnum.DATA,
			Component: ProfileData,
		},
		{
			path: path + InvestorRouteNamesEnum.HISTORY,
			Component: ProfileHistory,
		},
		{
			path: path + InvestorRouteNamesEnum.REQUISITES,
			Component: ProfileRequisites,
		},
		{
			path: path + InvestorRouteNamesEnum.ANALYTICS,
			Component: ProfileAnalytics,
		},
		{
			path: path + InvestorRouteNamesEnum.QUALIFICATION,
			Component: ProfileQualification,
		},
	];

	return (
		<PersonalDataProvider>
			<ProjectsProvider>
				<QualificationProvider>
					<QuestionsProvider>
						<ProfileContent routes={ProfileRoutes} />
						{/* {ww < ADAPTIVE_BREAKPOINTS.xl && (
							<ProfileMobileMenu navItems={INVESTOR_NAVIGATION} />
						)}
						<InvestRequestModal />
						<InvestRequestConfirmModal />
						<SuccessModal
							message={"Денежные средства успешно инвестированы"}
							setVisible={setRequestSuccessModal}
							visible={requestSuccessModal}
						/>

						<InvestCancelModal />
						<InvestCancelConfirmModal />
						<SuccessModal
							message={"Средства скоро вернутся на Ваш счёт!"}
							setVisible={setCancelSuccessModal}
							visible={cancelSuccessModal}
						/> */}
					</QuestionsProvider>
				</QualificationProvider>
			</ProjectsProvider>
		</PersonalDataProvider>
	);
};
