import React, { FC, useContext, useEffect } from "react";
import AsideNav from "@components/profile/Nav/ProfileNav";
import { IInvestorInfo, UserDataKeysEnum } from "@features/user/types";
import { useAppActions } from "@hooks/useAppActions";
import { matchPath, useLocation } from "react-router-dom";
import userApi from "@api/userApi";
import utilsApi from "@api/utilsApi";
import { ProfileAside } from "@components/profile/Aside/Aside";
import { Fade } from "@components/common/Transitions/Fade";
import { InvestorRouteNamesEnum, RouteNamesEnum } from "@app/router";
import useInvestorStatusCheck from "@hooks/useInvestorStatusCheck";
import {
	ChatIcon,
	DocumentIcon,
	InfoIcon,
	ProfileIcon,
	UsersIcon,
} from "@components/profile/Nav/icons";
import { SectionsCodeEnum } from "@features/utils/types";
import { IProfileNavigationItem } from "@common/types";
import InvestorProfileBalance from "@components/investor/Balance/ProfileBalance";
import { profileContext } from "@context/profileContext";
import isEmpty from "lodash.isempty";
import { usePoolingInterval } from "@hooks/usePoolingInterval";
import AsideReverseEntityLink from "@components/profile/Aside/ReversEntityLink/ReverseEntityLink";

enum NavNamesEnum {
	CABINET = "Мой кабинет",
	PROJECTS = "Проекты",
	CLUB = "Клуб инвесторов",
	QUESTIONS = "Вопросы",
}

export const INVESTOR_NAVIGATION: IProfileNavigationItem[] = [
	{
		Icon: ProfileIcon,
		name: NavNamesEnum.CABINET,
		path: RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.CABINET,
		code: SectionsCodeEnum.ACCOUNT,
	},
	{
		Icon: DocumentIcon,
		name: NavNamesEnum.PROJECTS,
		path: RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.PROJECTS,
		code: SectionsCodeEnum.PROJECTS,
	},
	{
		Icon: UsersIcon,
		name: NavNamesEnum.CLUB,
		path: RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.CLUB,
		code: SectionsCodeEnum.INVESTOR_CLUB,
	},
	{
		Icon: ChatIcon,
		name: NavNamesEnum.QUESTIONS,
		path: RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.QUESTIONS,
		code: SectionsCodeEnum.QUESTIONS,
	},
];

export const InvestorAside: FC = () => {
	const pollingInterval = usePoolingInterval(),
		{
			data: investorInfoData,
			isLoading: investorDataIsLoading,
			isError: investorInfoDataIsError,
		} = userApi.useInfoQuery(undefined, { pollingInterval }),
		{
			data: sectionsData = [],
			isLoading: sectionsDataIsLoading,
			isError: sectionsDataIsError,
		} = utilsApi.useSectionsQuery({ section_code: null }),
		{ setUserData, setSections } = useAppActions(),
		location = useLocation(),
		{ investorStatusUrl } = useInvestorStatusCheck(),
		{
			investorInfoIsError,
			investorInfoIsLoading,
			setInvestorInfoIsError,
			setInvestorInfoIsLoading,
		} = useContext(profileContext);
	useEffect(() => {
		investorInfoData &&
			setUserData({
				data: investorInfoData as IInvestorInfo,
				key: UserDataKeysEnum.II,
			});
	}, [investorInfoData]);

	useEffect(() => {
		setInvestorInfoIsError(investorInfoDataIsError);
		setInvestorInfoIsLoading(investorDataIsLoading);
	}, [investorDataIsLoading, investorInfoDataIsError]);

	useEffect(() => {
		if (!isEmpty(sectionsData)) {
			setSections(sectionsData);
		}
	}, [sectionsData]);

	return (
		<>
			<ProfileAside
				userDataIsLoading={investorInfoIsLoading}
				isLoadingError={investorInfoIsError || sectionsDataIsError}
				toPersonalDataLink={{
					inProp:
						matchPath(RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.DATA, {
							path: location.pathname,
							exact: false,
						}) === null,
					to:
						investorStatusUrl ||
						RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.DATA,
				}}>
				<Fade
					inProp={
						!investorInfoIsLoading &&
						!investorInfoIsError &&
						!sectionsDataIsLoading &&
						!sectionsDataIsLoading
					}>
					{/* <InvestorProfileBalance /> */}
					<AsideNav items={INVESTOR_NAVIGATION} />

					<div className='my-auto pt-5 pb-4 colgroup'>
						<AsideReverseEntityLink
							title={"О платформе"}
							to={`${process.env.REACT_APP_ROOT_LANDING_URL}about.html`}
							external
						/>
						<AsideReverseEntityLink
							title={"Документы"}
							to={`${process.env.REACT_APP_ROOT_LANDING_URL}documents.html`}
							external
						/>
						<AsideReverseEntityLink
							title={"Получить инвестиции"}
							to={`${process.env.REACT_APP_ROOT_LANDING_URL}borrower.html`}
							external
						/>
					</div>
				</Fade>
			</ProfileAside>
		</>
	);
};
